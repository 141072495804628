/*
 * sweet-scroll
 *
 * https://github.com/tsuyoshiwada/sweet-scroll
 */
import SweetScroll from 'sweet-scroll';

export default () => {
    const links = document.querySelectorAll('.js-scroll a[href^="#"]');

    if (!links) {
        return;
    }

    // スクロール完了後にfocus処理とURL操作を実行
    const afterScrollHandler = (target, linkHash) => () => {
        target.tabIndex = 0;
        target.focus();
        target.removeAttribute('tabindex');
        history.pushState(null, null, linkHash);
    };

    for (const i of links) {
        const link = i;
        const target = (link.getAttribute('href') === '#top') ? document.documentElement : document.querySelector(link.getAttribute('href'));
        const linkHref = link.getAttribute('href');

        // href="#top"だとスクロールが実行出来ないので、href="#"に変更
        if (link.getAttribute('href') === '#top') {
            link.setAttribute('href', '#');
        }

        const sweetScroll = new SweetScroll({ //eslint-disable-line
            trigger: `.js-scroll a[href="${link.getAttribute('href')}"]`,
            header: '.header__inner',
            after: afterScrollHandler(target, linkHref)
        });
    }
};
