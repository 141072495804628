const setting = {
    breakTab: 1024,
    breakSp: 768,
    openClass: 'is-open',
    currentClass: 'is-current',
    hiddenClass: 'is-hidden',
    showClass: 'is-show',
    fixedClass: 'is-fixed'
};

export default setting;
