/**
 * ディスクロージャー機能
 * @constructor
 * @classdesc : ロード時にパネルが閉じているディスクロージャー機能
 *
 * @param  {String} rootSelector : ディスクロージャーのルート要素のセレクター
 * @param  {String} panelSelector : ディスクロージャーのパネル要素のセレクター
 * @param  {String} panelInnerSelector : ディスクロージャーのパネルインナー要素のセレクター
 * @param  {String} triggerSelector : ディスクロージャーの開閉のトリガーになる要素のセレクター
 * @param  {String} openClass : ディスクロージャーが開いている際に付与するclass名
 *
 */
export default (rootSelector, panelSelector, panelInnerSelector, triggerSelector, openClass) => {
    const Disclosure = class {
        /*
         * @param  {HTMLElement} : rootElement : ディスクロージャーウィジェットのルート要素
         * @param  {Number} : panelIndex : パネルのID属性値として付与するインデックス番号
         *
         */
        constructor(rootElement, panelIndex) {
            /*
             *
             * @type {HTMLElement} : this.root : ディスクロージャーウィジェットのルート要素
             * @type {HTMLElement} : this.panel : 高さを可変させるパネル要素
             * @type {HTMLElement} : this.panelInner : パネルの高さを取得する為のインナー要素
             * @type {HTMLElement} : this.trigger : イベントトリガー（button要素）に置き換わる要素
             * @type {String} : this.openClass : パネルを開いた際に付与するclass名
             * @type {String} : this.panelId  : パネルに付与するID属性値
             * @type {Number} : this.panelHeight : パネルを開閉させる際に使用するパネルの最大値
             * @type {Boolean} : this.isTransition : パネルがトランジション中かを判定するフラグ。
             *
             */
            this.root = rootElement;
            this.panel = this.root.querySelector(panelSelector);
            this.panelInner = this.root.querySelector(panelInnerSelector);
            this.trigger = this.root.querySelector(triggerSelector);
            this.openClass = openClass;
            this.panelId = `disclosurePanel${panelIndex}`;
            this.panelHeight = this.panelInner.clientHeight;
            this.isTransition = false;

            // 属性値付与
            this.panel.id = this.panelId;

            /*
             *
             * ボタン生成
             *
             * this.triggerの親要素を取得して、this.triggerの位置に生成したボタンを追加する。
             *
             */

            // 要素取得＆生成
            this.triggerParent = this.trigger.parentElement;
            this.btn = document.createElement('button');

            // コンテンツ生成・属性値付与・要素追加
            this.btn.innerHTML = this.trigger.innerHTML;
            this.triggerParent.innerHTML = '';
            this.btn.setAttribute('aria-expanded', false);
            this.btn.setAttribute('aria-controls', this.panelId);
            this.triggerParent.appendChild(this.btn);

            /*
             * イベント付与
             *
             * トランジションしている時はthis.isTransitionがtrueになっている。
             *
             */
            this.btn.addEventListener('click', () => {
                if (this.isTransition) {
                    return;
                }

                this.isTransition = true;

                if (this.root.classList.contains(this.openClass)) {
                    this.panelClose();
                } else {
                    this.panelOpen();
                }
            });

            this.panel.addEventListener('transitionend', (e) => {
                // トランジションしているプロパティが”height”では無い時return
                if (e.propertyName !== 'height') {
                    return;
                }

                if (this.btn.getAttribute('aria-expanded') === 'true') {
                    this.panel.style.height = 'auto';
                } else {
                    this.root.classList.remove(this.openClass);
                }

                this.isTransition = false;
            });
        }

        /*
         * パネルを開ける処理
         *
         * @return {Void}
         *
         */
        panelOpen() {
            this.panelHeight = this.panelInner.clientHeight;
            this.root.classList.add(this.openClass);

            this.panel.style.height = '0px';

            setTimeout(() => {
                this.btn.setAttribute('aria-expanded', true);
                this.panel.style.height = `${this.panelHeight}px`;
            }, 100);
        }

        /*
         * パネルを閉じる処理
         *
         * @return {Void}
         *
         */
        panelClose() {
            this.panelHeight = this.panelInner.clientHeight;
            this.panel.style.height = `${this.panelHeight}px`;

            setTimeout(() => {
                this.btn.setAttribute('aria-expanded', false);
                this.panel.style.height = '0px';
            }, 100);
        }
    };

    const disclosures = document.querySelectorAll(rootSelector);

    if (disclosures.length === 0) {
        return;
    }

    Array.prototype.forEach.call(disclosures, (item, idx) => {
        const disclosure = new Disclosure(item, idx); //eslint-disable-line
    });
};
