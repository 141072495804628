export default (targetAreaSelector) => {
    // 要素取得
    const main = document.querySelector(targetAreaSelector);
    const links = main.querySelectorAll('a[href$=".pdf"]:not(.js-noIcon), a[href$=".PDF"]:not(.js-noIcon), a[target="_blank"]:not(.js-noIcon)');

    if (!links) {
        return;
    }

    const pageLang = document.documentElement.getAttribute('lang');
    let blankText = '';
    let pdfText = '';

    // 言語によって、svgのtitleタグの内容を変更
    switch (pageLang) {
    case 'ja':
        blankText = '新しいウィンドウで開く';
        pdfText = 'PDFファイル';
        break;

    case 'en':
        blankText = 'Open in new window';
        pdfText = 'PDF file';
        break;

        // どの言語にも該当しない場合は英語表記
    default:
        blankText = 'Open in new window';
        pdfText = 'PDF file';
        break;
    }

    const option = {
        pdf: `<svg class="m-pdfIcon" viewBox="0 0 14 17" width="14" height="17"><title>${pdfText}</title><path d="M5.63,6.71c0,.24,0,.54,0,.86a9.93,9.93,0,0,1,0,1.07A6.68,6.68,0,0,1,5.45,9.8,5.16,5.16,0,0,1,5,10.93L5,11l-.05.08,0,.09,0,.08c-.32.1-.65.22-1,.35a7.29,7.29,0,0,0-.88.46,3.85,3.85,0,0,0-.68.54,1.39,1.39,0,0,0-.36.59.67.67,0,0,0,0,.24.91.91,0,0,0,0,.23A1,1,0,0,0,2,13.9l.14.19a1.35,1.35,0,0,0,.19.16.94.94,0,0,0,.22.12.94.94,0,0,0,.22.07,1,1,0,0,0,.24,0,1.54,1.54,0,0,0,.61-.14,2.78,2.78,0,0,0,.62-.43,4.59,4.59,0,0,0,.64-.71,7.88,7.88,0,0,0,.66-1l.49-.08.8-.1.8-.09.53,0q.41.4.78.72a6.23,6.23,0,0,0,.7.5,3.37,3.37,0,0,0,.64.31,2,2,0,0,0,.57.1,1.26,1.26,0,0,0,.33,0,.81.81,0,0,0,.29-.12.92.92,0,0,0,.25-.19,1.06,1.06,0,0,0,.2-.26,1,1,0,0,0,.09-.21.83.83,0,0,0,0-.22.78.78,0,0,0,0-.22A.85.85,0,0,0,12,12a1.44,1.44,0,0,0-.55-.51,3.53,3.53,0,0,0-.86-.36,7.62,7.62,0,0,0-1-.23c-.32,0-.62-.08-.89-.1l-.07-.07-.06-.08-.07-.08-.08-.07a5.57,5.57,0,0,1-.69-1,7.26,7.26,0,0,1-.49-1.07,9,9,0,0,1-.32-1c-.08-.32-.14-.61-.18-.84,0,0,0-.1,0-.15s0-.1,0-.14a.6.6,0,0,1,0-.12l0-.11a.82.82,0,0,0-.09-.3.5.5,0,0,0-.15-.17.53.53,0,0,0-.17-.08l-.15,0L6,5.53a.45.45,0,0,0-.11,0l-.1.06-.09.08a.42.42,0,0,0-.09.14.74.74,0,0,0,0,.14.28.28,0,0,0,0,.13.49.49,0,0,0,0,.12s0,.06,0,.1a.5.5,0,0,0,0,.12.59.59,0,0,0,0,.13.59.59,0,0,0,0,.13ZM3.1,13.38l.1-.09a.69.69,0,0,1,.13-.11l.17-.13.21-.13-.2.2-.17.14-.13.08-.11,0Zm7.73-1h-.08l-.12,0-.16-.07-.19-.1.17,0,.15.06.13.06.1,0ZM6.59,9.75a2.69,2.69,0,0,0,.16.29,2.54,2.54,0,0,0,.16.28,3.18,3.18,0,0,0,.17.27l.18.26-.2,0-.34,0-.33,0-.17,0a2.49,2.49,0,0,1,.11-.29c0-.1.07-.2.1-.31a2.81,2.81,0,0,0,.08-.31c0-.11.06-.21.08-.32Z"/><path d="M7.14,0H0V17H14V4.65L12.88,3.49,11.76,2.32,10.64,1.16,9.51,0Zm3.27,2.47L10.9,3l.5.51.5.52h-2V2ZM1.08,12.22V1.06H8.83v4h4.09V15.94H1.08Z"/></svg>`,

        blank: `<svg class="m-blankIcon" viewBox="0 0 11 11" width="11" height="11"><title>${blankText}</title><path class="blankIcon__fill" d="M9.500,10.500 L2.500,10.500 L2.500,9.500 L9.500,9.500 L9.500,2.500 L10.500,2.500 L10.500,9.500 L10.500,10.500 L9.500,10.500 Z"/><path class="blankIcon__stroke" fill-rule="evenodd" stroke-width="1px" stroke-linecap="square" stroke-linejoin="miter" fill="none" d="M0.500,0.500 L8.500,0.500 L8.500,8.500 L0.500,8.500 L0.500,0.500 Z"/></svg>`
    };

    if (
        !main ||
        links.length <= 0
    ) {
        return;
    }

    Array.prototype.forEach.call(links, (link) => {
        const linkHref = link.getAttribute('href').toLocaleLowerCase();
        let target = link.querySelector('.js-iconText');

        target = target ? target : link;

        if (/.pdf$/.test(linkHref)) {
            target.innerHTML += option.pdf;
        } else {
            target.innerHTML += option.blank;
        }
    });
};
