const common = {
    window,
    html: document.documentElement,
    body: document.body,
    mediaQuery: window.matchMedia('(max-width: 1024px)')
};

/**
 * ハンバーガーメニュー（グローバルナビ）
 */
export default class HamburgerMenu {
    /**
     * @param {HTMLElement} element 機能構築の起点となるHTML要素
     */
    constructor(element) {
        this.root = element;
        this.button = {};
        this.flyout = {};
        this.flyoutButton = {};
        this.buttonSet = {};
        this.inertElement = {};
        this.link = {};
        this.isMenuOpened = false;
        this.isFlyoutOpened = false;
        this.tempItem = {};
        this.timeoutId = 0;
    }

    /**
     * 初期処理
     */
    init() {
        this.set();
        this.bindEvent();
    }

    /**
     * 機能の準備・設定
     */
    set() {
        this.button = this.root.querySelector('.js-hamburgerMenu__btn');
        this.flyout = this.root.querySelectorAll('.js-hamburgerMenu__flyout');
        this.flyoutButton = this.root.querySelectorAll('.js-hamburgerMenu__flyoutBtn');
        this.buttonSet = this.root.querySelectorAll('.js-hamburgerMenu__flyoutBtn, .js-hamburgerMenu__flyoutContent');
        this.inertElements = document.querySelectorAll('.header__localNav, .l-main, .l-footer');
        this.link = document.querySelectorAll('.js-hamburgerMenu__flyoutBtn');

        this.isMenuOpened = this.root.open;

        this.checkViewPort();
    }

    /**
     * イベント登録
     */
    bindEvent() {
        this.button.addEventListener('click', () => {
            // メニューオープン & SP幅ならクラス付与（overflow: hidden）
            if (this.isMenuOpened && common.mediaQuery.matches) {
                common.html.classList.add('is-hamburgerMenu-opened');

                this.inertElements.forEach((inertElement) => {
                    inertElement.setAttribute('inert', true);
                });
            } else {
                common.html.classList.remove('is-hamburgerMenu-opened');

                this.inertElements.forEach((inertElement) => {
                    inertElement.removeAttribute('inert');
                });
            }

            this.isMenuOpened = this.root.open;
        });

        this.flyoutButton.forEach((flyoutButton, index) => {
            flyoutButton.addEventListener('click', (event) => {
                if (!common.mediaQuery.matches) {
                    event.preventDefault();
                }
            });

            flyoutButton.addEventListener('mouseover', () => {
                setTimeout(() => {
                    if (!common.mediaQuery.matches) {
                        this.openFlyout(index);
                    }
                }, 70); // mouseleave とタイム合わせないとちゃんと動かない時がある
            });

            flyoutButton.addEventListener('focus', () => {
                if (!common.mediaQuery.matches) {
                    setTimeout(() => {
                        this.openFlyout(index);
                    }, 70); // blur してから focus、という順番を守るために、blur より長いタイムを設定する必要がある
                }
            });
        });

        this.flyout.forEach((flyout) => {
            const hookset = flyout.querySelectorAll('a, summary');

            hookset.forEach((hook) => {
                hook.addEventListener('blur', () => {
                    if (!common.mediaQuery.matches) {
                        setTimeout(() => {
                            if (document.activeElement.closest('.js-hamburgerMenu__flyout') !== flyout) {
                                this.closeFlyoutAll();
                            }
                        }, 50); // フォーカスが完全に移動するタイムラグ対策
                    }
                });
            });
        });

        // OPTIMIZE: 連続して発生するイベントに対して処理しているためパフォーマンス的に改善の余地あり
        document.addEventListener('mousemove', (event) => {
            // mouseleave の時点で hover してる要素を取得する
            if (!common.mediaQuery.matches) {
                // throttle
                if (this.timeoutId === 0) {
                    this.timeoutId = window.setTimeout(() => {
                        this.tempItem = event.target;
                        this.timeoutId = 0; // reset
                    }, 40);
                }
            }
        });

        this.buttonSet.forEach((button) => {
            button.addEventListener('mouseleave', () => {
                if (!common.mediaQuery.matches) {
                    setTimeout(() => {
                        /*
                         * console.log(button.parentNode === this.tempItem.parentNode);
                         * console.log(button.parentNode);        // mouseleave した要素の親
                         * console.log(this.tempItem.parentNode); // mouseleave した後に hover してる要素の親
                         */

                        if (button.parentNode === this.tempItem.parentNode) {
                            return;
                        }

                        this.closeFlyoutAll();
                    }, 70); // mouseover とタイム合わせないとちゃんと動かない時がある
                }
            });
        });

        common.mediaQuery.addEventListener('change', () => {
            this.checkViewPort();
        });
    }

    checkViewPort() {
        if (common.mediaQuery.matches) {
            // SPは初期状態でメニュー閉
            this.root.open = false;
            this.inertElements.forEach((inertElement) => {
                inertElement.removeAttribute('inert');
            });
        } else {
            // PCは初期状態でメニュー開
            this.root.open = true;
        }
    }

    closeFlyoutAll() {
        this.flyout.forEach((flyout) => {
            flyout.open = false;
        });

        this.numberRecent = null;
        this.isFlyoutOpened = false;
    }

    openFlyout(index) {
        this.flyout[index].open = true;
    }

    // inert属性
}
