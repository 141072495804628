import setting from './widget/setting';

import HamburgerMenu from './widget/hamburgerMenu';
import LinkCurrent from './widget/linkCurrent';
import pageScroll from './widget/pageScroll';
import addIcon from './widget/addIcon';
import fadeInContents from './widget/fadeInContents';
import disclosure from './widget/disclosure';
import addNewIcon from './widget/addNewIcon';
import snsLinkIcon from './widget/snsLinkIcon';
import Tab from './widget/tab';

(() => {
    // typesquareのscript読み込み完了後に実行
    window.addEventListener('DOMContentLoaded', () => {
        // HamburgerMenu
        document.querySelectorAll('.js-HamburgerMenu').forEach((element) => {
            const instance = new HamburgerMenu(element);

            instance.init();
        });

        // LinkCurrent
        document.querySelectorAll('.header__contents').forEach((element) => {
            const instance = new LinkCurrent(element, {
                items: '.header__navItem',
                links: '.header__category'
            });

            instance.init();
        });

        document.querySelectorAll('.l-localNav').forEach((element) => {
            const instance = new LinkCurrent(element, {
                items: '.localNav__categoryName, .localNav__categoryList > li',
                links: '.localNav__categoryName > a, .localNav__categoryList > li > a'
            });

            instance.init();
        });

        // pageScroll : scroll
        pageScroll();

        // addIcon : add PDF icon & blank icon
        addIcon('.l-main');

        // fadeInContents : fadeIn effect
        fadeInContents('.js-fadeInItem', setting.showClass);

        // disclosure
        disclosure('.js-faqDisclosure', '.faqDisclosure__answer', '.faqDisclosure__answerInner', '.faqDisclosure__questionInner', setting.openClass);

        // addNewIcon : add New icon to item
        addNewIcon(`
            .m-colLayout [class$="__date"],
            .uniTopMod-pressSection [class$="__date"],
            .uniMod-topicsSection [class$="__date"],
            .uniMod-colLayout__date,
            .uniTopMod-topNewsArea__date
        `);

        // snsLinkIcon : SNS icon link in press detail page.
        snsLinkIcon();

        /*
         * tab
         * setTabPanel('.js-tabWidget', '.js-tabWidget tabWidget__list', '.js-tabWidget tabWidget__listItem', '.js-tabWidget tabWidget__link', '.js-tabWidget tabWidget__content');
         */

        document.querySelectorAll('.js-tabWidget').forEach((element) => {
            const instance = new Tab(element, {
                listSelector: '.tabWidget__list',
                listItemSelector: '.tabWidget__listItem',
                linkSelector: '.tabWidget__link',
                contentSelector: '.tabWidget__content',
                currentClass: setting.currentClass
            });

            instance.init();
        });
    });
})();
