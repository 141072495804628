/*
 * NEWアイコン付与機能
 *
 * @param  {String} : targetSelector : NEWアイコンを付与するか判定する要素のセレクター
 *
 */
export default (targetSelector) => {
    // 要素取得
    const targets = document.querySelectorAll(targetSelector);

    // ページ表示時の日付を取得＆設定
    const nowDate = new Date();
    const baseDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate());

    /*
     * 項目の公開日によってNEWアイコンを付与するか判定する。
     *
     * @return {HTMLElement}
     *
     * @param  {HTMLElement} : target : 公開日付を判定する項目要素
     * @param  {String} : dateNum : 各項目の公開日付の文字列
     *
     */
    const checkAddIcon = (target, dateNum) => {
        /*
         *
         * @type {Date} : checkDate : 判定対象の日付をDateオブジェクトとして生成
         *
         * 年・月・日の項目だけで作成する。
         *
         */
        let itemYear;
        let itemMonth;
        let itemDay;

        if (document.documentElement.getAttribute('lang') === 'ja') {
            [itemYear, itemMonth, itemDay] = dateNum.split('.');
        } else {
            // enページの時、dateNumは「Xxx.1,2020（月の省略形、（ドット）、日、（カンマ）、西暦）」形式
            itemYear = dateNum.split(',')[1];  //eslint-disable-line

            // 月の文字列表記を数字に変換する。
            switch (dateNum.split('.')[0]) {
            case 'Jan':
                itemMonth = 1;
                break;
            case 'Feb':
                itemMonth = 2;
                break;
            case 'Mar':
                itemMonth = 3;
                break;
            case 'Apr':
                itemMonth = 4;
                break;
            case 'May':
                itemMonth = 5;
                break;
            case 'Jun':
                itemMonth = 6;
                break;
            case 'Jul':
                itemMonth = 7;
                break;
            case 'Aug':
                itemMonth = 8;
                break;
            case 'Sep':
                itemMonth = 9;
                break;
            case 'Oct':
                itemMonth = 10;
                break;
            case 'Nov':
                itemMonth = 11;
                break;
            case 'Dec':
                itemMonth = 12;
                break;

            default:
                break;
            }

            itemDay = dateNum.split('.')[1].split(',')[0];  //eslint-disable-line
        }

        const checkDate = new Date(itemYear, itemMonth - 1, itemDay);

        /*
         * @type {Number} : dayDiff : ページ表示日と判定要素の公開日付の日数差
         *
         * 差はミリ秒単位になる為、日に単位を変更する。
         *
         */
        const dayDiff = (baseDate - checkDate) / 1000 / 60 / 60 / 24;

        // 公開日が現在日付よりも30日より前の時はreturn
        if (dayDiff > 30) {
            return;
        }

        const iconElem = document.createElement('span');

        iconElem.classList.add('m-newIcon');
        iconElem.textContent = 'NEW';

        target.appendChild(iconElem);
    };

    if (targets.length <= 0) {
        return;
    }

    Array.prototype.forEach.call(targets, (target) => {
        const targetDate = target.innerHTML;

        checkAddIcon(target, targetDate);
    });
};
