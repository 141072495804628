/**
 * タブ機能
 * @constructor
 * @classdesc : リンクのhrefと一致するIDを持つパネルを開く
 *
 */
export default class Tab {
    /**
     * @param {HTMLElement} rootElement タブのルート要素
     * @param {object} option エレメントのセレクターオプション
     */
    constructor(element, option) {
        /** @type {HTMLElement} rootElement タブのルート要素 */
        this.root = element;
        /** @type {object} option エレメントのセレクターオプション */
        this.option = option;

        /** @type {HTMLElement} this.list タブリスト要素 */
        this.list = this.root.querySelector(option.listSelector);
        /** @type {NodeListOf<HTMLElement>} this.listItems タブリストのアイテム要素 */
        this.listItems = this.root.querySelectorAll(option.listItemSelector);
        /** @type {NodeListOf<HTMLElement>} this.links タブリストのリンク要素 */
        this.links = this.root.querySelectorAll(option.linkSelector);
        /** @type {NodeListOf<HTMLElement>} this.contents タブのパネル要素 */
        this.contents = this.root.querySelectorAll(option.contentSelector);
        /** @type {String} this.currentClass カレント用のクラス */
        this.currentClass = option.currentClass;
        /** @type {Map} this.combinationData タブリストと対になるパネルのデータ */
        this.combinationData = new Map();
    }

    /**
     * 初期処理
     */
    init() {
        this.setAttribute();
        this.setCombinationData();
        this.setDefaultCurrentTab();
        this.bindEvent();
    }

    /**
     * 初期設定
     * aria属性/role属性の付与
     *
     * @returns {Void}
     */
    setAttribute() {
        this.list.setAttribute('role', 'tablist');

        this.listItems.forEach((listItem) => {
            listItem.setAttribute('role', 'presentation');
        });

        this.links.forEach((link) => {
            /** タブのID */
            const id = link.getAttribute('href').replace('#', '') || '';

            link.setAttribute('role', 'tab');
            link.setAttribute('aria-controls', id);
            link.setAttribute('aria-selected', 'false'); // 一旦全部閉じる
            link.setAttribute('id', `${id}__panel`);
        });

        this.contents.forEach((content) => {
            /** タブパネルのID */
            const contentID = content.getAttribute('id');

            content.setAttribute('role', 'tabpanel');
            content.setAttribute('tabindex', '0');
            content.setAttribute('hidden', 'hidden'); // 一旦全部閉じる
            content.setAttribute('aria-labelledby', `${contentID}__panel`);
        });
    }

    /**
     * 初期設定
     * 対になるリンクボタンとパネルのデータを取得
     *
     * @returns {Void}
     */
    setCombinationData() {
        this.links.forEach((link) => {
            /** タブのID */
            const id = link.getAttribute('href').replace('#', '') || '';
            /** リンクと対になるパネル要素 */
            const content = id ? Array.from(this.contents).find((item) => item.id === id) : null;
            /** カレント用クラス付与されているかを判定するフラグ */
            const isCurrent = link.classList.contains(this.currentClass);

            this.combinationData.set(id, {
                id,
                link,
                content,
                isCurrent
            });
        });
    }

    /**
     * 初期表示
     * 優先順位に基づき、ロード時にタブを展開する
     *
     * @returns {Void}
     */
    setDefaultCurrentTab() {
        /** URLのハッシュ */
        const hash = location.hash.replace('#', '');
        /** URLのハッシュと同じhrefを持つリンク */
        const sameHashLink = this.combinationData.get(hash);
        /** カレント指定のリンク */
        const currentLink = Array.from(this.combinationData.values()).find((obj) => obj.isCurrent);

        // 優先順位 1位：ハッシュ付きURLのタブがあれば開く
        if (hash && sameHashLink) {
            this.switch(hash);
        }

        // 優先順位 2位： .is-currentを持つタブを開く
        if (!sameHashLink && currentLink) {
            this.switch(currentLink.id);
        }

        // 優先順位 3位：1,2いずれでもない場合は、最初のタブを開く
        if (!sameHashLink && !currentLink) {
            this.switch(Array.from(this.combinationData.keys())[0]);
        }
    }

    /**
     * タブパネル開閉
     * @param {string} id hide
     */
    switch(id) {
        this.combinationData.forEach((data, key) => {
            this[key === id ? 'show' : 'hide'](key);
        });
    }

    /**
     * タブパネルを開く
     * @param {string} id MapデータのID
     */
    show(id) {
        this.combinationData.get(id).link.setAttribute('aria-selected', 'true');
        this.combinationData.get(id).link.classList.add(this.currentClass);
        this.combinationData.get(id).content.removeAttribute('hidden');
    }

    /**
     * タブパネルを閉じる
     * @param {string} id MapデータのID
     */
    hide(id) {
        this.combinationData.get(id).link.setAttribute('aria-selected', 'false');
        this.combinationData.get(id).link.classList.remove(this.currentClass);
        this.combinationData.get(id).content.setAttribute('hidden', 'hidden');
    }

    bindEvent() {
        this.combinationData.forEach((data, key) => {
            data.link.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation(); // アンカーリンクのハッシュを伝搬させない

                this.switch(key);
            });
        });

        // ページ内でタブ外にある、タブへのリンクがフックの場合
        document.querySelectorAll('a[href*="#"]').forEach((link) => {
            const href = link.getAttribute('href');
            /** 「#」以降のIDを取得 */
            const id = href.substring(href.indexOf('#') + 1);

            // IDがタブのパネルと一致し、かつタブ内のリンクでない場合にのみイベントを付与
            if (this.combinationData.has(id) && !this.root.contains(link)) {
                link.addEventListener('click', (event) => {
                    event.preventDefault();
                    this.switch(id);
                });
            }
        });
    }
}
