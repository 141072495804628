/**
 * グロナビ・ローカルナビ カレント表示
 */
export default class LinkCurrent {
    /**
     * @param {HTMLElement} element 機能構築の起点となるHTML要素
     * @param {object} option 機能拡張オプション
     */
    constructor(element, option) {
        this.root = element;
        this.option = option;

        this.items = {};
        this.links = {};
        this.currentItem = {};
        this.hrefs = [];
        this.location = '';
    }

    /**
     * 初期処理
     */
    init() {
        this.set();
        this.bindEvent();
    }

    /**
     * 機能の準備・設定
     */
    set() {
        this.location = location.href;

        this.items = this.root.querySelectorAll(this.option.items);
        this.links = this.root.querySelectorAll(this.option.links);

        this.links.forEach((link, index) => {
            const href = link.getAttribute('href') || link.dataset.href;

            if (this.location.indexOf(href) !== -1) {
                this.hrefs.push(index);
            }
        });

        this.currentItem = this.items[this.hrefs[this.hrefs.length - 1]];
    }

    /**
     * イベント登録
     */
    bindEvent() {
        if (!this.hrefs.length) {
            return;
        }

        this.currentItem.classList.add('is-current');
        this.currentItem.setAttribute('aria-current', true);
    }
}
