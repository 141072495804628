/**
 *
 * プレスリリース詳細ページ SNSアイコンリンク
 *
 * プレスリリース詳細ページ内にある各SNSリンクアイコンをクリックした際に別windowで表示する処理
 *
 */
export default () => {
    window.addEventListener('load', () => {
        const root = document.querySelector('.m-snsListLink');

        if (!root) {
            return;
        }

        const facebookLink = root.querySelector('.is-facebook a');
        const twitterLink = root.querySelector('.is-twitter a');
        const lineLink = root.querySelector('.is-line a');

        // facebookのアイコンをクリックした時に別窓で表示
        facebookLink.addEventListener('click', (e) => {
            e.preventDefault();

            window.open(e.currentTarget.getAttribute('href'), 'FBwindow', 'width=650, height=450, menubar=no, toolbar=no, scrollbars=yes');
        });

        // twitterのアイコンをクリックした時に別窓で表示
        twitterLink.addEventListener('click', (e) => {
            e.preventDefault();

            window.open(e.currentTarget.getAttribute('href'), 'tweetwindow', 'width=650, height=470, personalbar=0, toolbar=0, scrollbars=1, sizable=1');
        });

        // LINEのアイコンをクリックした時に別窓で表示
        lineLink.addEventListener('click', (e) => {
            e.preventDefault();

            window.open(e.currentTarget.getAttribute('href'), 'LINEwindow', 'width=650, height=470, personalbar=0, toolbar=0, scrollbars=1, sizable=1');
        });
    });
};
