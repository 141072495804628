/*
 * intersection-observer : polyfill
 *
 * https://github.com/w3c/IntersectionObserver/tree/master/polyfill
 */
import 'intersection-observer';

export default (targetSelector, showClass) => {
    const targets = document.querySelectorAll(targetSelector);

    if (targets.length < 0) {
        return;
    }

    const options = {
        root: null,
        rootMargin: '50px 0px',
        threshold: 0
    };

    const callback = (entries, observerObj) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add(showClass);
                observerObj.unobserve(entry.target);
            }
        });
    };

    const observer = new IntersectionObserver(callback, options);

    targets.forEach((target) => {
        observer.observe(target);
    });
};
